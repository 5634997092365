.logo {
  text-align: center;
  margin-top: 5vh;
}

.errorBanner {
  position: absolute;
  padding: 0 5vw;
  top: 10%;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  top: 15%;
}

.inputs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.monthInput, .dayInput {
  flex-basis: 20%;
}

.yearInput {
  flex-basis: 40%;
}

.submitButton {
  margin-top: 30%;
  width: 100%;
}

.text {
  margin-bottom: 5%;
}

.title {
  font-size: 24px;
  margin-bottom: 0%;
}

.browseWrap {
  font-size: 12px;
  margin: 24px 12px 0 12px;
  text-align: center;
}

.link {
  text-decoration: none;
}

.link:visited {
  color: blue;
}
