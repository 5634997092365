.headerContainer {
  width: 100vw;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 2px 1px #F9F9FA;
}

.infoContainer {
  background-color: #F9F9FA;
  width: 100%;
}

.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 5% 0;
  max-width: 500px;
  margin: 0 auto;
}

.title {
  padding: 0;
  width: 50%;
  font-size: 14px;
  text-align: left;
  font-weight: bold;
  margin-top: 0;
}

.numQuestions {
  margin-top: 0;
  width: 50%;
  text-align: right;
  font-size: 14px;
}

.text {
  margin: 20px auto;
  padding-left: 5%;
  padding-right: 5%;
}