.infoBanner {
  margin-top: 20px;
  margin-bottom: 20px;
}

.formChecklistDescription {
  padding: 20px;
}

.completeLink {
  padding-top: 6px;
  text-decoration: none;
  font-size: 12px;
  line-height: 14px;
}

.completeLink:visited {
  color: #774d9a; /*Tempus brand purple*/
}

.checklistContentRow {
  display: flex;
}

.checklistCheckbox {
  padding-left: 20px;
  margin-left: auto;
  margin-right: 0px;
}

.checklistLink {
  text-decoration: none;
}

.checklistLink:visited {
  color: #774d9a; /*Tempus brand purple*/
}

.checklistCompleteDoc {
  padding-top: 6px;
  font-size: 12px;
  line-height: 14px;
  font-style: italic;
}

.checklistThankYouSection {
  text-align: center;
  padding-top: 60px;
}

.checklistThankYouText {
  padding-top: 24px;
}

.checklistAlert {
  text-align: center;
  padding-top: 20px;
}

.rootVerificationAlert {
  width: 100% !important;
  max-width: none !important;
}

.verificationAlert {
  padding-top: 20px;
  padding-bottom: 30px;
}

.title {
  padding-bottom: 20px;
}

.submitButton {
  text-align: center;
  padding-bottom: 30px;
}

.centerBlock {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.attestation {
  text-align: center;
  padding-bottom: 10px;
}
