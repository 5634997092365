.NotFound {
  text-align: center;
  margin: 60px auto;
}

.NotFound img {
  width: 180px;
}

.NotFound h2 {
  font-weight: 400;
  margin-bottom: 15px;
}

.NotFound span {
  font-size: 14px;
  font-weight: 300;
}
