.container {
  display: flex;
  flex-direction: column;  
  align-items: center;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 30px;
  width: 100%;
}

.title {
  font-weight: bold;
  width: 80%;
  line-height: 22px;
  font-size: 16px;
  text-align: left;
}