.options {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.answer {
  height: 6%;
  width: 80%;
  max-width: 400px;
  line-height: 6vh;
  margin: 1% auto 1%;
  box-shadow: 0px 1px 3px 1px #E9E9EA;
  border-radius: 8px;
  border: 2px solid transparent;
}

.selected {
  background-color: #C7DDF6;
  border: 2px solid #206EF3;
}