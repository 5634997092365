.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.header {
  margin-top: 0;
  font-size: 18px;
  margin-bottom: 30px;
}

.text {
  width: 80%;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 1.25;
  text-align: center;
}
