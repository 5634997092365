#okta-widget-container {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  flex-grow: 1;
  min-height: fit-content;
  align-items: center;

  * {
    box-sizing: inherit;
  }

  h2 {
    text-align: center;
  }

  h3 {
    font-weight: 300;
    margin: 0;
    text-align: center;
  }

  a : {
    text-decoration: underline;
  }

  .focused-input {
    box-shadow: none;
  }

  #okta-sign-in {
    margin: 0;
    border: none;
    box-shadow: none;
    background: inherit;
    font-family: inherit;

    a {
      font-family: 'IBM Plex Sans', sans-serif;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 16px;
      text-align: center;
      text-decoration: underline;
    }

    label {
      color: #000000;
      font-family: 'IBM Plex Sans', sans-serif;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 14px;
      padding-bottom: 0px;
    }

    .auth-content {
      padding: 0px 20px 140px 20px;

      .o-form-label {
        padding-top: 0px;
        padding-bottom: 5px;
      }

      .o-form-head {
        color: #000000;
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: 24px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 29px;
        margin-bottom: 40px;
      }

      .o-form-theme {
        padding-bottom: 20px;
      }

      .o-form-fieldset {
        margin-bottom: 20px;
      }

      .o-form-input {
        position: relative;

        .o-form-input-error.o-form-explain {
          color: #d7303b;
          font-family: 'IBM Plex Sans', sans-serif;
          font-size: 12px;
          line-height: 14px;
          padding-left: 0px;
          padding-bottom: 5px;

          .icon {
            display: none;
          }
        }
      }

      .o-form-button-bar {
        text-align: left;
      }

      .o-form-theme {
        padding: 0;
      }

      .o-form {
        textarea {
          font-size: 12px;
        }
      }

      .input-fix {
        border: 1px solid #e2e2e3;
        box-shadow: 0 2px 4px 0 #f1f1f2;
      }

      .o-form-has-errors {
        .infobox-error {
          p {
            font-family: 'IBM Plex Sans', sans-serif;
            color: #000000;
            font-size: 12px;
            font-weight: 500;
          }
        }

        .infobox,
        .infobox-md {
          border: 0px;
          background-color: #ffd2d2;
          border-radius: '5px';
        }

        .error-16:before {
          content: '\e055';
          color: #d7303b;
        }

        .infobox-error:before {
          background-color: #ffd2d2;
        }

        .infobox:before {
          width: auto;
        }

        .infobox {
          padding: 12px 10px 12px 56px !important;
        }

        .infobox > .icon {
          left: 22px;
        }

        @media (max-width: 600px) {
          .infobox {
            padding: 12px 10px 12px 46px !important;
          }

          .infobox > .icon {
            left: 15px;
          }
        }

        .input-fix {
          border: 1px solid #d7303b;
          background-color: #ede1e3;
          box-shadow: 0 3px 4px 0 #f1f1f2;
        }
      }

      #okta-signin-password {
        &.password-with-toggle {
          padding-right: 30px;
        }
      }

      .eyeicon::before {
        font-size: 20px;
      }

      .icon.input-icon {
        display: none;
      }

      .icon.input-tooltip,
      .input-icon-divider {
        display: none;
      }

      .sms-request-button {
        height: 45px;
        line-height: 45px;
      }

      .button-primary {
        border: none;
        height: 45px;
        background: #3e3e3f;
        transition: all 0.3s;
        box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0.5), 0 1px 2px 0 #d8d8d8;
        color: #ffffff;
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 14px;

        .link-button-disabled {
          color: #5a5a5d;
        }

        &:hover,
        &:focus {
          background: #3e3e3f;
          border-color: white;
        }
        &:active {
          background: #3e3e3f;
          box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5), 0 1px 2px 0 rgba(0, 0, 0, 0.5);
        }
      }
    }

    .auth-footer {
      text-align: center;

      .link {
        &.js-help-link {
          display: none;
        }
      }

      .link.help.js-help {
        display: none;
      }

      .help-links.js-help-links {
        display: block !important;

        li {
          margin: 0;
        }
      }
    }

    .app-view-container {
      padding: 10px;
      text-align: center;
      line-height: 1.3;

      img {
        width: 100%;
        max-width: 500px;
        margin-left: -22px;
      }

      &.empty-app-state {
        a {
          color: #7b7b7e;
          font-weight: 500;
          font-size: 12px;
        }
      }
    }

    .legal-disclaimer-link {
      text-decoration: underline;
      color: #9f9f9f;
    }

    .okta-sign-in-header.auth-header {
      display: none;
    }

    #terms-of-use-disclaimer {
      font-family: 'IBM Plex Sans', sans-serif;
      color: #5a5a5d;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 16px;
      text-align: center;
      margin-bottom: 20px;
    }

    #terms-of-use-login-disclaimer {
      font-family: 'IBM Plex Sans', sans-serif;
      color: #5a5a5d;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 16px;
      text-align: center;
      margin-top: 25px;
    }

    #reset-password-link {
      color: #000000;
      font-family: 'IBM Plex Sans', sans-serif;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 16px;
      margin: 30px 0px 20px;
    }

    #activate-account-link {
      color: #000000;
      font-family: 'IBM Plex Sans', sans-serif;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 16px;
      margin-bottom: 20px;
    }

    #password-complexity-disclaimer {
      padding-bottom: 73px;
      padding-top: 10px;

      p {
        font-family: 'IBM Plex Sans', sans-serif;
        color: #5a5a5d;
        font-size: 12px;
        line-height: 18px;
        text-align: left;
        padding-bottom: 5px;
      }

      #password-complexity-disclaimer-header {
        font-weight: 500;
      }
    }

    #welcome-header {
      color: #000000;
      font-family: 'IBM Plex Sans', sans-serif;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
      margin-top: -20px;
      margin-bottom: 40px;
    }
  }

  #okta-sign-in.auth-container {
    &:focus {
      outline: none;
    }
    overflow: hidden;

    .button.link-button-disabled {
      background-color: #a0a0a3;
    }

    input[type='submit'] {
      &:focus {
        box-shadow: none;
      }
    }

    input[type='password']::placeholder {
      color: #7b7b7e;
      font-family: 'IBM Plex Sans', sans-serif;
      font-size: 12px;
      letter-spacing: 0;
    }

    input[type='text'] {
      &:focus {
        box-shadow: none;
      }
    }

    input[type='text']::placeholder {
      color: #7b7b7e;
      font-family: 'IBM Plex Sans', sans-serif;
      font-size: 12px;
      letter-spacing: 0;
    }
  }

  @media (max-height: 800px) {
    #okta-sign-in .auth-content {
      padding: 0px 20px;
    }
  }
}
