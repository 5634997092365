.alert {
  height: 10vh;
  display: flex;
  align-items: center;
  padding-left: 5% !important;
  padding-right: 5% !important;
  margin: 5% 0;
  background-color: #BE2F38 !important;
  color: #FFFFFF;
  border-radius: 8px !important;
}

.error {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.text {
  margin: 0 !important;
  padding-left: 20px;
  font-size: 1em;
  white-space: normal;
  line-height: 1.25;
  overflow-wrap: break-word;
}