.headerText {
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  font-size: 32px;
}

.zeroAssessmentsWrapper {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}

.zeroAssessmentsText {
  margin-left: 10px;
}

.subheaderText {
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 1rem;
}

.startButtonContainer {
  width: 100%;
  padding-bottom: 30px;
  text-align: center;
}

.startButton {
  width: 60%;
  max-width: 400px;
}

.landingPage {
  padding-top: 10vh;
  margin-top: 5vh;
  width: 85vw;
  max-width: 300px;
}
