.alertContainer {
  margin-top: 15%;
}

.alert {
  background-color: #29293D !important;
  color: #fff;
  font-size: 18px;
}

.icon {
  color: #fff;
}

.dismissButton {
  color: #fff;
}