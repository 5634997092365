.submitButtonContainer {
  margin-top: 10%;
  text-align: center;
}

.submitButton {
  width: 80vw;
  margin: 0 auto;
  font-size: 16px;
}

.submitHelpText {
  color: #7B7B7E;
  font-size: 14px;
  margin-top: 5%;
  margin-bottom: 5%;
}

.nextButtonText {
  display: inline-flex;
  align-items: center;
  font-size: 16px;
}