.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.icon {
  width: 60px !important;
  height: 60px !important;
  color: #24A878;
  margin-bottom: 20px;
}

.header {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 36px;
}

.text {
  margin-bottom: 0;
  font-size: 18px;
}
