.loadingContainer {
  height: 100vh;
  width: 80vw;
  text-align: center;
}

.loadingText {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-duration: 3s;
}

@keyframes breatheIn {
  0% { opacity: 0; }
  25% { opacity: 1; }
  50% { opacity: 0; }
}

@keyframes breatheOut {
  50% { opacity: 0; }
  75% { opacity: 1; }
  100% { opacity: 0; }
}

.breatheIn {
  animation-name: breatheIn;
}

.breatheOut {
  animation-name: breatheOut;
  animation-direction: reverse;
  animation-delay: 1.5s;
}